<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <agency-left-section class="hidden-sm-and-down" />
        <agency-volunteers-section />
        <agency-right-section class="hidden-md-and-down" />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyLeftSection from "../AgencyLeftSection.vue";
import AgencyRightSection from "../AgencyRightSection.vue";
import AgencyVolunteersSection from "./AgencyVolunteersSection.vue";

export default {
  components: {
    AgencyVolunteersSection,
    AgencyLeftSection,
    AgencyRightSection
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      interestsDialog: false,
      availabilitiesDialog: false,
      congratesDialog: false,
      notifications: [],
      errorNotifications: []
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  }
};
</script>
<style scoped></style>
