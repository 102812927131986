<template>
  <div class="feed-body">
    <div class="feed-contents">
      <v-row style="width: 100%">
        <v-col cols="12" class="white--text">
          Filter
          <div class="d-flex flex-row">
            <v-select
              :items="cities"
              v-model="filter.city"
              dense
              outlined
              hide-details
              @change="onChange"
              background-color="white"
              placeholder="City"
            />
            <v-select
              :items="ageOptions"
              v-model="filter.age"
              dense
              outlined
              hide-details
              @change="onChange"
              background-color="white"
              placeholder="Age"
            />
            <v-btn icon @click.stop="onSendMessage"
              ><v-icon color="white">mdi-message</v-icon></v-btn
            >
          </div>
        </v-col>
        <v-col v-for="volunteer in volunteers" :key="volunteer._id" cols="6">
          <agency-volunteer-profile-card :_id="volunteer" />
        </v-col>
        <div
          v-if="volunteers.length == 0 && !loading"
          class="d-flex flex-column justify-center align-center w-100 white--text"
        >
          <div class="mb-3 mt-15">
            <v-img
              :src="require('@/assets/svg/empty-post.svg')"
              style="width: 60px; height: 50px"
              contain
            />
          </div>
          No volunteer fanned
        </div>
      </v-row>
      <v-progress-circular
        indeterminate
        color="amber"
        v-if="loading"
        class="mt-10"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AgencyVolunteerProfileCard from "../profile/AgencyVolunteerProfileCard.vue";
export default {
  components: { AgencyVolunteerProfileCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      volunteers: [],
      filter: {},
      cities: [],
      ageOptions: [
        "13-18",
        "19-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65 and older"
      ]
    };
  },
  methods: {
    ...mapActions("agency", {
      getFanned: "getFanned",
      getVolunteerCities: "getVolunteerCities"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreVolunteers() {
      this.loadingMore = true;
      this.loadMore({ skip: this.volunteers.length })
        .then(() => {
          setTimeout(() => {
            this.loadingMore = false;
          }, 2500);
        })
        .catch(error => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    goToProfile(id) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id }
      });
    },
    onChange() {
      this.onLoadData();
    },
    onLoadData() {
      this.loading = true;
      this.loadCompleted = 0;
      let params = { _id: this.profile._id };
      if (this.filter && this.filter.city) params.city = this.filter.city;
      if (this.filter && this.filter.age) params.age = this.filter.age;
      this.volunteers = [];
      this.getFanned(params)
        .then(res => {
          this.loading = false;
          this.volunteers = [...res];
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });

      this.getVolunteerCities(this.profile._id)
        .then(res => {
          this.cities = ["", ...res];
          console.log(res);
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onSendMessage() {
      this.$router.push({
        name: "agency-message",
        query: { to: "All Fans" }
      });
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  },
  watch: {
    profile(newValue) {
      if (newValue._id) {
        this.loading = true;
        this.loadCompleted = 0;
        this.onLoadData();
      }
    }
  },
  mounted() {
    if (this.profile._id) {
      this.onLoadData();
    }
  }
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
</style>
